export default {
  ACCOUNT: {
    ACCOUNT_READ: 'ACCOUNT_READ',
    ACCOUNT_WRITE: 'ACCOUNT_WRITE',
  },
  BANK: {
    BANK_READ: 'BANK_READ',
    BANK_WRITE: 'BANK_WRITE',
  },
  YEAR: {
    YEAR_READ: 'YEAR_READ',
    YEAR_WRITE: 'YEAR_WRITE',
  },
  TRANSACTION: {
    TRANSACTION_READ: 'TRANSACTION_READ',
    TRANSACTION_WRITE: 'TRANSACTION_WRITE',
    TRANSACTION_APPROVE: 'TRANSACTION_APPROVE',
  },
  CUSTOMER_ADVANCE: {
    CUSTOMER_ADVANCE_READ: 'CUSTOMER_ADVANCE_READ',
    CUSTOMER_ADVANCE_WRITE: 'CUSTOMER_ADVANCE_WRITE',
    CUSTOMER_ADVANCE_APPROVE: 'CUSTOMER_ADVANCE_APPROVE',
  },
  SUPPLIER_ADVANCE: {
    SUPPLIER_ADVANCE_READ: 'SUPPLIER_ADVANCE_READ',
    SUPPLIER_ADVANCE_WRITE: 'SUPPLIER_ADVANCE_WRITE',
    SUPPLIER_ADVANCE_APPROVE: 'SUPPLIER_ADVANCE_APPROVE',
  },
  SUPPLIER_ADVANCE_ADJUSTMENT: {
    SUPPLIER_ADVANCE_ADJUSTMENT_READ: 'SUPPLIER_ADVANCE_ADJUSTMENT_READ',
    SUPPLIER_ADVANCE_ADJUSTMENT_WRITE: 'SUPPLIER_ADVANCE_ADJUSTMENT_WRITE',
    SUPPLIER_ADVANCE_ADJUSTMENT_APPROVE: 'SUPPLIER_ADVANCE_ADJUSTMENT_APPROVE',
  },
  COST: {
    COST_READ: 'COST_READ',
    COST_WRITE: 'COST_WRITE',
  },
  PROFIT: {
    PROFIT_READ: 'PROFIT_READ',
    PROFIT_WRITE: 'PROFIT_WRITE',
  },
  SUPPLIER_PAYMENT: {
    SUPPLIER_PAYMENT_READ: 'SUPPLIER_PAYMENT_READ',
    SUPPLIER_PAYMENT_WRITE: 'SUPPLIER_PAYMENT_WRITE',
    SUPPLIER_PAYMENT_APPROVE: 'SUPPLIER_PAYMENT_APPROVE',
  },
  ACCOUNT_REPORT_BALANCE_SHEET: {
    ACCOUNT_REPORT_BALANCE_SHEET: 'ACCOUNT_REPORT_BALANCE_SHEET',
  },
  ACCOUNT_REPORT_LEDGER: {
    ACCOUNT_REPORT_LEDGER: 'ACCOUNT_REPORT_LEDGER',
  },
  ACCOUNT_REPORT_INCOME_STATEMENT: {
    ACCOUNT_REPORT_INCOME_STATEMENT: 'ACCOUNT_REPORT_INCOME_STATEMENT',
  },
  ACCOUNT_REPORT_DETAILS_BY_CODE: {
    ACCOUNT_REPORT_DETAILS_BY_CODE: 'ACCOUNT_REPORT_DETAILS_BY_CODE',
  },
  ACCOUNT_REPORT_LEDGER_BY_CODE: {
    ACCOUNT_REPORT_LEDGER_BY_CODE: 'ACCOUNT_REPORT_LEDGER_BY_CODE',
  },
  ACCOUNT_REPORT_DETAILS_BALANCE_BY_CODE: {
    ACCOUNT_REPORT_DETAILS_BALANCE_BY_CODE: 'ACCOUNT_REPORT_DETAILS_BALANCE_BY_CODE',
  },
  EXPENSE_BUDGET: {
    EXPENSE_BUDGET_READ: 'EXPENSE_BUDGET_READ',
    EXPENSE_BUDGET_WRITE: 'EXPENSE_BUDGET_WRITE',
    EXPENSE_BUDGET_APPROVE: 'EXPENSE_BUDGET_APPROVE',
  },
  ACCOUNT_REPORT_DETAILS: {
    ACCOUNT_REPORT_DETAILS: 'ACCOUNT_REPORT_DETAILS',
  },
  ACCOUNT_REPORT_EXPENSE_VARIANCE: {
    ACCOUNT_REPORT_EXPENSE_VARIANCE: 'ACCOUNT_REPORT_EXPENSE_VARIANCE',
  },
  ACCOUNT_REPORT_LOAN_CALCULATOR: {
    ACCOUNT_REPORT_LOAN_CALCULATOR: 'ACCOUNT_REPORT_LOAN_CALCULATOR',
  },
  ACCOUNT_REPORT_CONTRA_ENTRY: {
    ACCOUNT_REPORT_CONTRA_ENTRY: 'ROLE_ACCOUNT_REPORT_CONTRA_ENTRY',
  },
}
